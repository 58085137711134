import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTypeFolioSkuForCountry, isTypeFolio } from 'src/helpers/typeFolioHelpers';
import { useCountryData } from 'src/hooks/useCountryData';
import { PromotionBundleOption, SanityPromotionBundles } from 'src/queries/groq/productPromotionBundle';
import { State } from 'src/redux/reducers';
import { SanityPromotionBundle } from 'src/typings/sanityTypes';

const ONE_DAY_IN_MS = 60 * 60 * 24 * 1000;

const isActivePromotionBundle = ({ startDate, endDate }: SanityPromotionBundle) =>
  new Date(startDate).getTime() < Date.now() && new Date(endDate).getTime() > Date.now();

const findFirstActivePromotionBundle = (promotions?: SanityPromotionBundles) =>
  promotions?.find((product) => isActivePromotionBundle(product));

export interface PromotionBundleState {
  activePromotionBundle?: SanityPromotionBundle;
  isValidPromotionBundle: (sku: string) => boolean;
  selectedBundle: PromotionBundleOption | null;
  setSelectedBundle: (promotionBundleOption: PromotionBundleOption | null) => void;
  relevantBundles?: PromotionBundleOption[];
}

export const PromotionBundleContext = createContext<PromotionBundleState | undefined>(undefined);
PromotionBundleContext.displayName = 'PromotionBundleContext';

export const PromotionBundleProvider: FC<{ bundles: SanityPromotionBundles }> = ({ bundles, children }) => {
  const { country } = useCountryData();

  const [activePromotionBundle, setActivePromotionBundle] = useState(() => findFirstActivePromotionBundle(bundles));
  const relevantBundles = activePromotionBundle?.bundles.filter(
    (bundle) =>
      !isTypeFolio(bundle.folio?.sku) ||
      (isTypeFolio(bundle.folio?.sku) && getTypeFolioSkuForCountry(country, 'black') === bundle.folio?.sku)
  );
  const editingBundleSku = useSelector((state: State) => state.managedPurchaseFlow.editingBundleSku);

  const defaultBundle = relevantBundles?.find((bundle) => bundle.defaultSelected);
  const editingBundle = relevantBundles?.find((bundle) => bundle.bundleSku === editingBundleSku);

  const [selectedBundle, setSelectedBundle] = useState(editingBundle ?? defaultBundle ?? null);

  const isValidPromotionBundle = (sku: string): boolean => {
    if (!activePromotionBundle) {
      return false;
    }

    return !!activePromotionBundle?.bundles.find((bundle) => sku === bundle.bundleSku);
  };

  useEffect(() => {
    if (activePromotionBundle) {
      const endingInMs = new Date(activePromotionBundle.endDate).getTime() - Date.now();

      if (endingInMs > 0 && endingInMs < ONE_DAY_IN_MS) {
        const timeout = setTimeout(() => setActivePromotionBundle(undefined), endingInMs);
        return () => clearTimeout(timeout);
      }
    }
  }, [activePromotionBundle]);

  return (
    <PromotionBundleContext.Provider
      value={{ activePromotionBundle, isValidPromotionBundle, selectedBundle, setSelectedBundle, relevantBundles }}
    >
      {children}
    </PromotionBundleContext.Provider>
  );
};

export const usePromotionBundleContext = (): PromotionBundleState => {
  const context = useContext(PromotionBundleContext);

  if (context === undefined) {
    throw new Error('usePromotionBundleContext was used outside of its Provider');
  }

  return context;
};
